// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet';

function MetaTags() {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      {/* <title>MountGit - Your Digital Growth Partner</title> */}
      {/* <meta 
        name="description" 
        content="Welcome to MountGit – your trusted partner for professional web development, hosting, domain registration, logo design, app development, e-commerce solutions, consultation, and strategy. Let us help you build a strong online presence and drive your digital growth." 
      />
      <meta 
        name="keywords" 
        content="MountGit, web development, website hosting, domain registration, logo design, app development, e-commerce, consultation, digital solutions, SEO optimization, social media advertising" 
      /> */}
      <meta name="author" content="MountGit" />
      {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="MountGit - Your Digital Growth Partner" />
      <meta 
        property="og:description" 
        content="Discover our professional web development, hosting, domain registration, logo design, app development, e-commerce solutions, and expert consultation services to elevate your online presence." 
      />
      <meta property="og:url" content="https://www.mountgit.com" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.mountgit.com/og-image.jpg" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="MountGit - Your Digital Growth Partner" />
      <meta 
        name="twitter:description" 
        content="MountGit offers professional web development, hosting, domain registration, logo design, app development, e-commerce solutions, and expert consultation to boost your digital presence." 
      />
      <meta name="twitter:image" content="https://www.mountgit.com/twitter-image.jpg" />

  {/* Location-Based Meta Tags for Local SEO */}
      {/* 
         Adjust these tags with your actual business location details. 
         In this example, we assume MountGit is based in Mumbai, India.
      */}
      <meta name="geo.region" content="IN" />
      <meta name="geo.placename" content="Gaya" />
      <meta name="geo.position" content="24.790344;84.992549" />
      <meta name="ICBM" content="24.790344, 84.992549" />















      {/* ... other meta tags ... */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Mountgit",
            "url": "https://www.mountgit.com",
            "logo": "https://www.mountgit.com/logo.png",
            "sameAs": [
              "https://www.facebook.com/yourprofile",
              "https://www.twitter.com/yourprofile"
            ],
            "branchOf": {
              "@type": "Organization",
              "name": "Mountgit"
            },
            "hasMap": "https://www.google.com/maps/place/MountGit",
            "location": [
              {
                "@type": "Place",
                "name": "Mountgit Gaya",
                "address": {
                  "@type": "white house compound Behind Mirza Ghalib College Near Mallick Chemistery",
                  "streetAddress": "white house compound Behind Mirza Ghalib College Near Mallick Chemistery 823001",
                  "addressLocality": "Gaya",
                  "addressRegion": "GAY",
                  "postalCode": "823001",
                  "addressCountry": "IN"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "24.790344",
                  "longitude": "84.992549"
                }
              },
              {
                "@type": "Place",
                "name": "MountGit gaya",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "456 Connaught Place",
                  "addressLocality": "New Delhi",
                  "addressRegion": "DL",
                  "postalCode": "110001",
                  "addressCountry": "IN"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "28.6139",
                  "longitude": "77.2090"
                }
              }
            ]
          }
        `}
      </script>

    </Helmet>
  );
}

export default MetaTags;

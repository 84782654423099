// import React, { useRef } from 'react';
// import './contact.css';
// import emailjs from '@emailjs/browser';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


// export function Contact(){

//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     toast("🚀Email Send Successfully!🥳");

//     emailjs.sendForm('service_t9zeooe', 'template_nwntg9a', form.current, 'V6c-mHcmJ0le53VEi')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//       e.target.reset()
//   };

//   return (
//     <div className="contact-container">
//       <div className="contact-form glass-effect">
//         <h2>Contact Us</h2>
//         <form ref={form} onSubmit={sendEmail}>
//           <div className="form-group">
//             <label htmlFor="name">Name</label>
//             <input type="text" id="name" name="from_name" className="form-control" placeholder="Your Name" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email</label>
//             <input type="email" id="email" name="from_email" className="form-control" placeholder="Your Email" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="message">Message</label>
//             <textarea id="message" name="message" className="form-control" placeholder="Your Message" rows="4" required></textarea>
//           </div>
//           <button type="submit" className="btn btn-outline-secondary submit-btn">Send Message</button>
//         </form>
//       </div>
//         <ToastContainer />
//     </div>
//   );
// };







import React, { useRef } from 'react';
import './contact.css';
// import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Get form values using FormData
    const formData = new FormData(e.target);
    const fromName = formData.get("from_name");
    const fromEmail = formData.get("from_email");
    const message = formData.get("message");

    // Construct pre-filled WhatsApp message
    const whatsappMessage = `Hello, my name is: ${fromName}. My email is: ${fromEmail}. My message: ${message}`;
    const encodedMessage = encodeURIComponent(whatsappMessage);
    // Replace with your actual WhatsApp number (include country code, no '+' sign)
    const whatsappNumber = '917903287161'; // Example number
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

    // Show toast notification
    toast("🚀 Message Sent Successfully!🥳");

    // Open WhatsApp chat in a new tab/window with the pre-filled message
    window.open(whatsappURL, '_blank');

    // Send email via EmailJS
    // emailjs.sendForm('service_t9zeooe', 'template_nwntg9a', form.current, 'V6c-mHcmJ0le53VEi')
    //   .then((result) => {
    //       console.log(result.text);
    //   }, (error) => {
    //       console.log(error.text);
    //   });
    
    // Reset form fields
    e.target.reset();
  };

  return (
    <div className="contact-container">
      <div className="contact-form glass-effect">
        <h2>Contact Us</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="from_name"
              className="form-control"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="from_email"
              className="form-control"
              placeholder="Your Email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              className="form-control"
              placeholder="Your Message"
              rows="4"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-outline-secondary submit-btn">
           <span className='fab fa-whatsapp'> Send Message</span>
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

import React, { useState } from 'react';
import './projects.css';

const projectsData = [
  {
    id: 1,
    title: 'School Website',
    description: "This project was designed to collect fee after admin login, The website includes Form for collecting fee, after submit it will save data in database and generate invoice. It is searchable and view PDF and can take printout of invoice and more functionality.",
    link: 'https://jovmtekari.online/',
  },
  {
    id: 2,
    title: 'Packer & Movers',
    description: "This project provides a modern, mobile-friendly, and visually appealing website that: ✔ Attracts potential customers searching for reliable moving services. ✔ Builds trust with a professional online presence. ✔ Boosts engagement with an interactive design, real-time quotes, and clear service details. 🚀 How This Project Helps in Business Growth? 🔹 Increases Online Visibility – More inquiries & customer reach. 🔹 Saves Time & Effort – Automated WhatsApp quote requests. 🔹 Builds Brand Reputation – A professional website enhances credibility. 🔹 Improves Customer Experience – Easy-to-use, responsive, and informative platform.",
    link: 'https://www.nehapack.com/',
  },
  {
    id: 3,
    title: 'Chemical Company Pvt. Ltd.',
    description: "Developed for Chemical Company Private Limited, this cutting-edge website project showcases premium epoxy resin and hardener products for bangle making, flooring, and tabletops. The solution features a fully responsive design with animated elements—including an auto-sliding banner, dynamic galleries, and smooth scrolling content—that work seamlessly across devices from desktops to mobile phones. With a focus on user experience and modern aesthetics, our project integrates advanced features such as a responsive carousel with auto-transition every 2 seconds, a flexible grid-based gallery, and comprehensive product information pages. This high-performance, SEO-optimized website not only elevates the brand's online presence but also drives customer engagement and business growth. Our expertise in developing such innovative digital experiences can help your business stand out in a competitive market.",
    link: 'https://amjad-bond1.web.app/',
  },
  {
    id: 4,
    title: 'Nursing School',
    description: "This project is a fully responsive, modern website developed specifically for a nursing training school. Built using React, JSX, and modern CSS techniques, the website offers a seamless user experience across all devices—from desktop monitors to mobile screens. Key Features: Dynamic Image Carousel, Responsive Design, Interactive Prospectus Section, Gallery and Facilities Showcase, Integrated Contact Form with WhatsApp, SEO & Metadata Optimized, Technologies Used: React & JSX, CSS & Media Queries, JavaScript, Font Awesome & Google Fonts",
    link: 'https://rktnursing.web.app/',
  },
  {
    id: 5,
    title: 'Portfolio',
    description: 'A responsive website template tailored for creative agencies and startups. With a focus on aesthetics and functionality, this project provides a versatile platform for showcasing portfolios, and showing projects.',
    link: 'https://my-portfolio-b9968.web.app/',
  },
];

export function Projects() {
  return (
    <div className="projects-container">
      <h2 className="projects-title">Our Projects</h2>
      <div className="row">
        {projectsData.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
}

function ProjectCard({ project }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = 100;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    if (isExpanded) {
      return project.description;
    }
    return project.description.length > characterLimit
      ? `${project.description.substring(0, characterLimit)}...`
      : project.description;
  };

  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <div className="project-card card h-100 shadow-sm">
        <div className="card-body">
          <h3 className="card-title">{project.title}</h3>
          <p className="card-text">{renderDescription()}</p>
          {project.description.length > characterLimit && (
            <button onClick={handleToggleExpand} className="read-more-btn">
              {isExpanded ? 'Show Less' : 'Read More'}
            </button>
          )}
          <a href={project.link} className="view-project">View Project</a>
        </div>
      </div>
    </div>
  );
}

// import React, { useState } from "react";
// import "./whatsApp.css"; 

// export function WhatsappDialog() {
//   const [isOpen, setIsOpen] = useState(false);

//   const openWhatsApp = () => {
//     window.open("https://wa.me/+917903287161", "_blank"); 
//   };

//   return (
//     <div className="whatsapp-container">
//       <div
//         className={`whatsapp-icon ${isOpen ? "active" : ""}`}
//         onClick={() => setIsOpen(!isOpen)}
//       >
//         <img
//           src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" onClick={openWhatsApp}
//           alt=""
//         />
//       </div>
//       {isOpen && (
//         <div className="whatsapp-dialog">
//           <div className="whatsapp-header">
//             <h2>MountGit</h2>
//           </div>
//           <div className="whatsapp-body">
//             <p>
//               Need dynamic website let's contact? <br />
//               Start a chat with us on WhatsApp!
//             </p>
//             <button onClick={openWhatsApp}>Start WhatsApp Chat</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }











import React from "react";
import { motion } from "framer-motion";
import "./whatsappButton.css";

export function WhatsAppButton(){
  const phoneNumber = "917903287161"; // Replace with your WhatsApp number (without '+' sign)
  const message = "Hello! Can I get more info on this?";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <motion.a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      whileHover={{ scale: 1.1 }}
    >
      <i className="fab fa-whatsapp"></i>
    </motion.a>
  );
};
